@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,700');

// Variables
@import "variables";

// Bulma
@import "~bulma/bulma.sass";

// jQuery UI
@import '~jquery-ui/themes/base/all.css';

.guest {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100vh;

	h1 {
		font-size: 5rem;
		line-height: 1;
		margin: -0.5rem 0 2.5rem;
	}
}

.striped-list {
	.columns {
		margin: 0;
		border-radius: $radius-small;

		&:nth-child(odd) {
			background-color: $white-ter;
		}

		&:nth-child(even) {
			.button.is-text:hover {
				background-color: $white-ter;
			}
		}
	}
}

.hero {
	.title {
		font-size: $size-2;
	}
}

.events .value {
	margin-bottom: 2em;
}

a.tile {
	position: relative;
	height: 0;
	padding-bottom: 75%;
	overflow: hidden;
	transition: transform 0.2s;

	&:hover {
		transform: scale(1.05);
	}

	span {
		position: absolute;

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: -1.5rem;
			height: 1px;
			width: 1rem;
			background-color: currentColor;
		}
	}
}
